// ** React Imports
import { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'
import moment from "moment-timezone"
import 'moment-timezone/node_modules/moment/locale/id'    // without this line it didn't work

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesId from '@assets/data/locales/id.json'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesId from '@src/assets/data/locales/id.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  id: { ...messagesId, ...userMessagesId }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  const tag = 'lang'

  // ** States
  const [locale, setLocale] = useState(()=> {
    const savedLang = localStorage.getItem(tag)
    let finalLang = null
    if (savedLang) {
      finalLang = savedLang
    }
    else {
      const defaultLang = 'id'
      localStorage.setItem(tag, defaultLang)
      finalLang = defaultLang
    }
    moment.locale(finalLang)
    return finalLang
  })

  const [messages, setMessages] = useState(menuMessages[locale])

  // ** Switches Language
  const switchLanguage = lang => {
    localStorage.setItem(tag, lang)
    moment.locale(lang)
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
