import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const sweetalert = (iconParam, msgParam) => {
  withReactContent(Swal).fire({
    position: 'top-end',
    icon: iconParam,
    title: msgParam,
    showConfirmButton: false,
    timer: 1500,
  });
};

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '');

export const getLocaleSeparator = () => {
  var list = ['a', 'b'];
  var s = list.toLocaleString();
  return s[1];
};

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' },
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: 'short', day: 'numeric' };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' };
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => getLocalStorageWithExpiry('_user');
export const getUserData = () => JSON.parse(getLocalStorageWithExpiry('_user'));
export const getSavedVersion = () => localStorage.getItem('version');

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole, urlImg, userId) => {
  if (userRole && urlImg && userId) {
    return `/view/${userId}/${urlImg}`;
  }
  if (userRole === 'admin') return '/';
  if (userRole === 'client') return '/access-control';
  return '/login';
};

export const multiSelectCustomStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    zIndex: 9999,
  }),
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontWeight: 'bold',
    color: 'white',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: 'white',
    // ':hover': {
    //   backgroundColor: selectThemeColors({}).colors.primary25,
    //   color: 'white',
    // },
  }),
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#006386', // for selected option bg-color
    primary25: '#0063861a', // for option hover bg-color
    // primary25: '#7367f01a', // for option hover bg-color
    // primary: '#C05239', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
});

// function to return ISO8601 in WIB timezone
export const toISO8601 = (datetime) => {
  var dt = datetime + '+07';
  return dt.replace(' ', 'T');
};

export const apiEndpoint = process.env.REACT_APP_ENDPOINT1;
export const apiEndpoint2 = process.env.REACT_APP_ENDPOINT2;
export const apiEndpointLogin = process.env.REACT_APP_ENDPOINT2;
export const sessionLifetime = process.env.REACT_APP_SESSION_LIFETIME ?? 120; // lifetime in minutes
export const apiKey = process.env.REACT_APP_API_KEY;
export const paging = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];
export const pagingRDT = [10, 20, 50, 100];
export const defaultStartDate = new Date(2020, 8, 1);

export const isProduction =
  apiEndpoint.toLowerCase().indexOf('https') >= 0 &&
  apiEndpoint.toLowerCase().indexOf('-dev') < 0 &&
  apiEndpoint.toLowerCase().indexOf('pasarmikro.id') >= 0;
const isDev = apiEndpoint.indexOf('-dev.pasarmikro.id') >= 0;
const isLocal =
  apiEndpoint.toLowerCase().indexOf('localhost') >= 0 ||
  apiEndpoint.indexOf('127.0.0.1') >= 0;
const versionCode = isProduction ? 'R' : isDev ? 'D' : isLocal ? 'L' : 'U';
export const version = (process.env.REACT_APP_VERSION ?? '') + versionCode;

export const gateways = ['durianpay'];

//available subjects
export const HOME = 'reports'; // gmv charts + user activity
export const OFFERMANAGEMENT = 'offerManagement';
export const LOANMANAGEMENT = 'loanManagement'; // fasilitas + atur fasilitas
export const USERMANAGEMENT = 'userManagement'; // registered users + rejected users
export const USERREGISTRATION = 'userRegistration';
export const USERVERIFICATION = 'userVerification';
export const USERPROFILE = 'userProfile';
export const USERPROFILECHANGE = 'userProfileChange';
export const BYUSERREPORT = 'byUserReport';
export const WITHDRAWAL = 'withdrawal'; // withdrawal + new withdrawal
export const DISPUTE = 'dispute';
export const ADMIN = 'admin'; // administrator + user role + permission
export const LOANTIER = 'loanTier'; // loar tier + user loan tier + discount
export const MASTERITEMS = 'masterItems';
export const SERVICEPROVISION = 'serviceProvision';
export const FEEMANAGEMENT = 'feeManagement'; // disbursement fee + withdrawal fee + topup fee
export const TRANSACTION = 'transaction';
export const INVENTORY = 'inventory';
export const DEPOSITHISTORY = 'depositHistory';
export const CLIK = 'clik';
export const ASLIRI = 'asliri';
export const APPSETTINGS = 'appSettings';
export const LOGS = 'logs';
export const BANKS = 'banks';
export const PUSHNOTIF = 'pushNotification';
export const FINANCIALNOTES = 'financialNotes';
export const HOLIDAY = 'holiday';
export const PIN = 'pin';
export const INBOX = 'inbox';
export const TOPUP = 'topup';
export const REFERRAL = 'referral';
export const TAGGING = 'usertag';
export const DEVICES = 'device';
export const OTP = 'otp';
export const VERIHUBS = 'verihubs';
export const CRONJOB = 'CRONJOB';
export const TRADESHORTCUT = 'tradeShortcut';
export const STORE = 'store';
export const COMMODITYCATEGORY = 'itemCategory';
export const PARTNER = 'partner';
export const TRANSACTIONFEE = 'transactionFee';
export const DATAINTEGRITY = 'integrity';
export const TALANGAN_APP = 'facilityApplication';
export const MAKERCHECKER = 'makerChecker';
export const QUESTIONNAIRE_TALANGIN = 'questionnaireTalangin';
export const VERSION = 'versionDistribution';
export const ROLES = 'roles';
export const REPO = 'repo';
export const TUTORIAL = 'tutorial';

export const ALL = 'all';
export const ABILITIES = [
  HOME,
  BYUSERREPORT,
  OFFERMANAGEMENT,
  LOANMANAGEMENT,
  USERMANAGEMENT,
  USERREGISTRATION,
  USERVERIFICATION,
  USERPROFILE,
  USERPROFILECHANGE,
  PIN,
  WITHDRAWAL,
  DISPUTE,
  ADMIN,
  LOANTIER,
  MASTERITEMS,
  SERVICEPROVISION,
  FEEMANAGEMENT,
  TRANSACTION,
  INVENTORY,
  DEPOSITHISTORY,
  CLIK,
  ASLIRI,
  HOLIDAY,
  APPSETTINGS,
  LOGS,
  BANKS,
  PUSHNOTIF,
  FINANCIALNOTES,
  INBOX,
  TOPUP,
  REFERRAL,
  TAGGING,
  DEVICES,
  OTP,
  VERIHUBS,
  CRONJOB,
  PARTNER,
  TRADESHORTCUT,
  STORE,
  COMMODITYCATEGORY,
  TRANSACTIONFEE,
  DATAINTEGRITY,
  TALANGAN_APP,
  MAKERCHECKER,
  QUESTIONNAIRE_TALANGIN,
  VERSION,
  REPO,
  TUTORIAL,

  ALL,
];

export const ABILITIESBUSINESS = [
  'business_all',
  'business_myapproval',
  'business_company',
  'business_withdrawal',
  'business_transaction_purchase',
  'business_transaction_sell',
  'business_topup',
  'business_talangin',
  'business_farmers',
  'business_farmers_plotting',
  'business_vessels',
  'business_whistleblowing',
  'business_trips',
];

//available actions
export const READ = 'read';
export const EXPORT = 'export';
export const APPROVE = 'approve';
export const RELEASE = 'release';
export const EDIT = 'edit';
export const MANAGE = 'manage';
export const ACTIONS = [READ, EXPORT, EDIT, APPROVE, RELEASE, MANAGE];
export const ACTIONSBUSINESS = [MANAGE, APPROVE, 'create', 'make', READ];

// local storage names used
export const localStorageNames = [
  'lang',
  'menuCollapsed',
  'skin',

  'dayOfWeekUserActivityChart',
  'hourlyUserActivityChart',
  'admins',
  'roleSelection',
  'userSelectAdmin',
  'permissions',
  'roles',
  'activity',
  'clikACR',
  'clikIND',
  'clikNEG',
  'exclusion',
  'userSelectExclusion',
  'kodeDebitur',
  'region',
  'logs',
  'mainLogs',
  'paymentLogs',
  'loanReport',
  'disputeTable',
  'dailyTransactionTable',
  'depositReport',
  'depositHistoryTable',
  'facility',
  'inventoryTable',
  'offerList',
  'transactionTable',
  'profileList',
  'individualUserReport',
  'provision',
  'serviceprovision_options',
  'provisionByService',
  'provisionByUser',
  'withdrawalPricingTable',
  'disbursementPricingTable',
  'loanTier',
  'masterItemTable',
  'userLoanTier',
  'userEditApproval',
  'profiles',
  'registeredUsers',
  'registrationApproval',
  'rejectedUsers',
  'verificationApproval',
  'withdrawalTable',
  'instantWithdrawalTable',
  'pushNotifListdata',
  'pushNotifListnotif',
  'pushNotifList',
  'bankList',
  'cashflowHistoryTable',
  'offerListPerUser',
  'pendingVerificationUsers',
  'disbursementSettingsTable',
  'transactionPerUser',
  'completeDepositHistory',
  'holiday',
  'bankSelect',
  'asliriNegativeRecord',
  'asliriProfessional',
  'outstandingFacility',
  'topupfee',
  'topup',
  'topupbri',
  'topuppg',
  'inbox',
  'inboxactivity',
  'inboxnews',
  'referral',
  'referralList',
  'referralSummary',
  'rejection',
  'sellersCredit',
  'verificationRejectedUsers',
  'loanTierSetting',
  'loanTierChange',
  'userTagging',
  'tagSelect',
  'userSelect',
  'devices',
  'otp',
  'refreshOnErrorCount',
  'verihubsCost',
  'verihubsLogs',
  'facesEnroll',
  'facesVerify',
  'transactionTradeShortcut',
  'partnerTable',
  'itemCategory',
  'store',
  'detailToko',
  'topupbca',
  'clikConsentReport',
  'transactionFee',
  'feeWaivedTransaction',
  'usersWithDirtyProvinceOrCity',
  'userWithInvalidBankInfo',
  'usersWithDeletedLevel',
  'usersWithMultipleIdentities',
  'inconsistentUsers',
  'usersWithNoServiceProvision',
  'usersWithNoUserLevelSetup',
  'clikConsentReportDetail',
  'dataIntegrity',
  'listOfBD',
  'userRateHistory',
  'usersWithDisabledTenors',
  'usersTagging',
  'outstandingWallet1',
  'outstandingWallet2',
  'topupbcatransfer',
  'facilityApplication',
  'makerChecker',
  'questionnaireTalangin',
  'questionnaireTalanginWelcoming Page',
  'questionnaireTalanginBusiness Actor Profile',
  'questionnaireTalanginBusiness Profile',
  'questionnaireTalanginSeller Type',
  'questionnaireTalanginBuyer Information',
  'questionnaireTalanginFinancial (purchases and sales)',
  'questionnaireTalanginFinancial (assets and liabilities)',
  'questionnaireTalanginAdditional information',
  'netPromoterScore',
  'talanginTenor',
  'outstanding',
  'outstandingWallet3',
  'depositHistoryWallet3',
  'autosettlement',
  'versionDistribution',
  'faceVerif',
  'selfieVerification',
  'autoRepo',
  'learning',
  'learning-report',

  'userData',
  'accessToken',
  'cronjob',
  'ktpValidation',
  'ocr',
];

// local storage with expiration
export function setLocalStorageWithExpiry(key, value) {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + sessionLifetime * 60000,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

// axios config
export const axiosConfig = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage._apiToken}`,
      Accept: 'application/json',
      'ngrok-skip-browser-warning': '69420',
    },
  };
};

export const adjustLink = (oldUrl) => {
  let newUrl = oldUrl;
  newUrl = newUrl.replace(
    'http://104.248.156.222:9001',
    'https://kelud-dev.pasarmikro.id:9443',
  );
  newUrl = newUrl.replace(
    'http://kelud-dev.pasarmikro.id:9001',
    'https://kelud-dev.pasarmikro.id:9443',
  );
  newUrl = newUrl.replace(
    'http://dev.pasarmikro.id:9001',
    'https://kelud-dev.pasarmikro.id:9443',
  );

  newUrl = newUrl.replace(
    'http://157.245.59.211:9001',
    'https://kelud.pasarmikro.id:9443',
  );
  newUrl = newUrl.replace(
    'http://kelud.pasarmikro.id:9001',
    'https://kelud.pasarmikro.id:9443',
  );

  // if (!isProduction) {
  //   newUrl = newUrl.replace('https://kelud.pasarmikro.id:9443', 'https://kelud-dev.pasarmikro.id:9443');
  // }

  return newUrl;
};

export const formatForFloatInput = (value, decimalSymbol) => {
  let newValue = value.length > 0 ? value : '0';

  // const decimalSymbol = ((1.1).toLocaleString().indexOf('.') > 0) ? '.' : ','
  newValue = newValue.replace(new RegExp(`[^0-9${decimalSymbol}]`, 'g'), '');

  // remove unwanted decimal point symbol
  var valueArr = newValue.split(decimalSymbol);
  newValue =
    valueArr.shift() +
    (valueArr.length ? decimalSymbol + valueArr.join('') : '');

  // remove unwanted leading zero
  // const reLeadingZero = new RegExp(`(?:(-)(?![0.]+(?![\d.]))|-)?\d*?([1-9]\d*|0)(?:(?:(\.\d*[1-9])|\.)\d*)?(?![\d.])`, 'gm');
  // value = value.replace(reLeadingZero, '$1$2$3')

  // remove unwanted leading zero
  while (newValue.charAt(0) == '0') {
    if (newValue.length == 1 || newValue.charAt(1) == decimalSymbol) break;
    newValue = newValue.substr(1);
  }
  return newValue;
};

export const durianPayLogStatus = (dplog) => {
  if (dplog == null) {
    return 'Request failed';
  } else {
    const lastStatus = dplog.lastStatus?.toLowerCase();
    if (lastStatus == 'success') {
      return 'Success';
    } else if (lastStatus == 'failed') {
      return 'Failed';
    } else if (lastStatus == 'partially_success') {
      return 'Partially success';
    } else {
      return 'Processing';
    }
  }
};

export const getPrivateImageLink = (link, userId) => {
  if (link && userId) {
    const params = `userid=${userId}&apikey=tangkubanperahu`;
    return `${link}${link.indexOf('?') < 0 ? '?' : '&'}${params}`;
  } else {
    return '';
  }
};
